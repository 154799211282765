import axios, { AxiosError } from "axios";

const API =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/api/v1"
    : "/api/v1";

console.log("API--> ", API);

const request = axios.create({
  baseURL: API,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

request.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError) => {
    const { status } = error.response || {};
    switch (status) {
      case 400:
        // bad request
        console.log(error.response);
        break;
      case 401:
        // Unauthorized
        console.log("Unauthorized.....");
        localStorage.removeItem("ux");
        window.location.reload();
        break;
      case 404:
        // NotFound
        console.log(error.response?.status);
        break;
      case 500:
        //InternalServerError
        console.log("server error");
        break;
      default:
        //Api not working
        console.log("an unknown error occurred");
        return Promise.reject(error);
    }
    return Promise.reject(error.response?.data);
  }
);

export default request;
